import React from "react"
import styled from "@emotion/styled"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"
import Seo from "../components/seo"
import TopicsSection from "../components/topics/topics-section"
import DynamicImage from "../components/dynamic-image"

const ContainerMain = styled.div`
  padding: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  a {
    text-decoration: underline;
  }
`

const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const ContainerBody = styled.div`
  margin-top: 40px;
  margin-bottom: 0px;
  width: 100%;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 36px;
    margin-top: 40px;
    text-align: left;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 36px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 32px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ul {
    p {
      margin-bottom: 0;
    }
    li {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 712px) {
      max-height: 356px;
    }
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
  }
  blockquote {
    border-left: #ddd0d0 solid 3.5px;
    padding: 0 12px;
  }
`

const ContainerImage = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  align-items: flex-start;
  vertical-align: top;
  margin-bottom: 0px;
`

const RandomImage = styled.img`
  width: 100%;
  max-width: 680px;
  object-fit: cover;
  margin-bottom: 0px;
  @media screen and (max-width: 374px) {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
`

const ContainerText = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 712px) {
    max-width: 100%;
  }
`

const Title = styled.p`
  width: 680px;
  color: #222222;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 45px;
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: left;
  @media screen and (max-width: 712px) {
    max-width: 100%;
  }
`

const PublishedDate = styled.p`
  height: 19px;
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 19px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0px;
`

const Tag = styled.p`
  display: flex;
  flex-wrap: wrap;
  .tag {
    margin-right: 4px;
  }
  .tag:last-child {
    margin-right: 0;
  }
  margin-top: 16px;
  margin-bottom: 0px;
`

const TagSpan = styled.span`
  display: inline-block;
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 12px;
  box-sizing: border-box;
  height: 24px;
  width: fit-content;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  background-color: #efefef;
  margin-right: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TopicsLink = ({ pageContext }) => {
  const { setIsAtContactPage } = UsePageContext()
  useState(() => {
    setIsAtContactPage(false)
  })

  const data = pageContext.data
  const title = data.title
  const date = data.createdAt
  const photo = data.topimage?.gatsbyImageData
  const author = data.author
  const tags = data.category
  const bodytext = data.bodytext.childMarkdownRemark.html
  const sectionData = pageContext.sectionData
  const slug = data.slug

  if (tags) {
    const tagsName = tags.name
    const tagsPhoto = tags.image.gatsbyImageData
    const showTopicDetails = (
      <TopicDetails
        title={title}
        photo={photo}
        tagsPhoto={tagsPhoto}
        date={date}
        author={author}
        tagsName={tagsName}
        bodytext={bodytext}
        sectionData={sectionData}
        slug={slug}
      />
    )
    return <>{showTopicDetails}</>
  } else {
    const tagsName = ""
    const tagsPhoto = "https://source.unsplash.com/collection/175083/640x360"
    const showTopicDetails = (
      <TopicDetailsNoTags
        title={title}
        photo={photo}
        tagsPhoto={tagsPhoto}
        date={date}
        author={author}
        tagsName={tagsName}
        bodytext={bodytext}
        sectionData={sectionData}
        slug={slug}
      />
    )
    return <>{showTopicDetails}</>
  }
}

const TopicDetails = ({
  title,
  photo,
  tagsPhoto,
  date,
  author,
  tagsName,
  bodytext,
  sectionData,
  slug,
}) => {
  return (
    <>
      <Seo title={`${title} | トピックス`} description="トピックス" />
      <ContainerMain>
        <Container>
          <ContainerImage>
            {photo && <DynamicImage image={photo} />}
            {!photo && <DynamicImage image={tagsPhoto} />}
          </ContainerImage>
          <ContainerText>
            <Title>{title}</Title>
            <PublishedDate>
              {date}　{author}
            </PublishedDate>
            <Tag>
              <TagSpan>{tagsName}</TagSpan>
            </Tag>
          </ContainerText>
          <ContainerBody dangerouslySetInnerHTML={{ __html: bodytext }} />
        </Container>
      </ContainerMain>
      <TopicsSection
        data={sectionData}
        tag={tagsName}
        slug={slug}
        from={"details"}
      />
    </>
  )
}

const TopicDetailsNoTags = ({
  title,
  photo,
  tagsPhoto,
  date,
  author,
  bodytext,
  sectionData,
  slug,
}) => {
  return (
    <>
      <Seo title={`${title} | トピックス`} description="トピックス" />
      <ContainerMain>
        <Container>
          <ContainerImage>
            {photo && <RandomImage src={photo} alt="post-cover" />}
            {!photo && <RandomImage src={tagsPhoto} alt="post-cover" />}
          </ContainerImage>
          <ContainerText>
            <Title>{title}</Title>
            <PublishedDate>
              `${date}　${author}`
              {/* {date}
              {"　"}
              {author} */}
            </PublishedDate>
          </ContainerText>
          <ContainerBody dangerouslySetInnerHTML={{ __html: bodytext }} />
        </Container>
      </ContainerMain>
      <TopicsSection
        data={sectionData}
        tag={null}
        slug={slug}
        from={"details"}
      />
    </>
  )
}

export default TopicsLink
